/*
  초기화면 ( home )
    OWL Start

*/
import React from "react";
import "./Home.css";
import LoaderButton from "../components/LoaderButton";

import { Auth } from 'aws-amplify';
import config from '../config';

export default function Home(props) {

  function handleClick(e) {
    e.preventDefault();
    // 생성정보 확인하여 팝업 띄우기.
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then( user => {
      var arg = "a=&b=&c="+user.username+"&d="+user.attributes.name+"&e="+user.attributes.phone_number+"&f="+user.attributes.email ;
      var url= config.owl_callback.starter+"?"+arg ; // awsin.mics
      //console.log (url) ;
      window.open(url,
        "CheckEyeDx　レセプトチェッカ",
        'scrollbars=no,status=no,titlebar=no,toolbar=no,status=no,resizable=yes,menubar=no,location=no,width=1024, height=700'
      );

    }).catch( err => {
      console.log("catch( err => {");
      console.log(err);
      //alert ("ログインをお願いします。") ;
      props.history.push("/login");
     });

  }

  return (
    <div className="Home">
      <div className="lander">
        <h1>医療機関向けクラウド版チェックアイDXレセプトチェッカー</h1>
          <LoaderButton block onClick={handleClick} bsSize="large" >
             Ｓｔａｒｔ
          </LoaderButton>

        <div class="wrap">
          <div class="box">
            <div class="in">
            <h4>システム障害等緊急連絡先は下記の通りです。</h4>
            <h4>平日10時～18時（土日・祝祭日・年末年始を除く）</h4>
            <h4>TEL：03-5287-5010</h4>
            </div>
          </div>
        </div>
  
      </div>
    </div>

  );
}
