/*
  #4285, NoAction ( home )
    OWL Start

*/
import React, { useState, useEffect } from "react";
import "../App.css";
import Modal from "react-modal";  // #4285, useState Add
import config from '../config'; // #4285, Add

function App() {

  const [isOpen, setIsOpen] = useState(true) ;  // #4285, Add 팝업 오픈
  const [jobDateTime, setJobDateTime] = useState('');   // 작업일시 정보  

  const customStyles={
    overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)",},
    content: {
      backgroundColor: "#eaeff4" , width: "93%", heigth: "100%", margin: "auto",
      borderRadius: "4px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", padding: "20px",
    },
  };
  // user session 체크
  useEffect(() => {
    onLoad();
  }, []);
  /*------------------------------------------------------
  -- onLoad
  ------------------------------------------------------*/
  async function onLoad() {
    console.log( "noAction...start") ;
    // 작업예정일시를 config.js 에서 가져와서 셋팅하자.
    console.log("작업예정시각정보:"+config.owl_callback.jobDateTime);
    setJobDateTime(config.owl_callback.jobDateTime ); 
  }
  return (
    <div className="Home">
      <div>
        <Modal isOpen={isOpen} style={customStyles}>
          <div className="modalBody">
            <h1>サービス一時停止</h1>
            <img src="img-note-guide02.png"></img>
            <h1>今、サーバー作業を進めています。。。</h1>
            <h3>{jobDateTime} </h3>
            <h3> システムアップデートのためご利用できません」 </h3>
          </div>
        </Modal>
      </div>
    </div>
  );
}
export default App;
