/*
  Signup (/signup) , ; 사용자가 회원가입 기능 업도록 한다.
  Create User (/createUser) ; 관리자가 직접 URL 이동
  adminCreateUser ( /AdminCreateUser) ; 관리자가 직접 URL 이동
  : 제거.
*/
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import "./App.css";

import config from './config'; // #4285, Add
import Modal from "react-modal";  // #4285, useState Add

function App(props) {

  const [isAuthenticating, setIsAuthenticating] = useState(true); // load user session
  const [isAuthenticated, userHasAuthenticated] = useState(false);  //Log status

  //const [<상태 값 저장 변수>, <상태 값 갱신 함수>] = useState(<상태 초기 값>);
  const [ curUserInfo, setCurUserInfo ] = useState('');  // Log 사용자 userid

  // #4285, Add 팝업 관련 설정
  const [jobDateTime, setJobDateTime] = useState('');   // 작업일시 정보 
  const [isOpen, setIsOpen] = useState(false) ;
  // 今日一日開かない 용.
  const [isChecked, setChecked] = useState(false); // 체크박스를 체크했는가  
  
  const openModal = () => {   setIsOpen(true) ; };
  const closeModal = () => {   
    console.log ( "closeModel 버튼 클릭했습니다.  ")
    console.log ( "'오늘 하루 보지 않기' 체크 여부? ", isChecked ) ;  
    if (isChecked){
      // localStorage 에 'popupNoShow' 1일동안의 타임을 저장.
      let date = new Date();
      date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000)); //1일*24시간*60분*60초*1000
      localStorage.setItem("popupNoShow", date.getTime());
    }
    setIsOpen(false) ; 
  };
  const customStyles={
    overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)",},
    content: {
      backgroundColor: "#eaeff4" , width: "93%", heigth: "100%", margin: "auto",
      borderRadius: "4px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", padding: "20px",
    },
  };

  // 체크박스가 change 됐을 때  
  const handleChange = (e) => {
    const changed = e.target.checked;
    console.log ( "체크박스를 체크했는가 ", changed ) ;    
    changed ? setChecked(true) : setChecked(false);
  };

  // user session 체크
  useEffect(() => {
    onLoad();
  }, []);
  /*------------------------------------------------------
  -- onLoad
  ------------------------------------------------------*/
  async function onLoad() {
    try {
      /* #4285 작업공지 팝업 여부 확인 Start */
      var isPopup = isServerWorking();
      // isPopup -> -1:팝업X, 0:팝업/작업가능, 1:팝업/작업금지.
      if ( isPopup == -1 ){
         console.log( "현재일시 보다 작다, 팝업 노출 X " );
      } else if ( isPopup == 0){
        console.log( "현재일시 보다 크다, 팝업 노출 Ok" );        
      } else if ( isPopup == 1){// 1
        console.log( "현재작업시간 일치, 팝업 노출 Ok, 액션 금지! 로그인, start 금지.." );
        props.history.push("/noAction");
      }
      /* #4285 , End */

      await Auth.currentSession();  // AWS
      userHasAuthenticated(true);
      var cUser = await Auth.currentAuthenticatedUser();
      // 처음 로드시 상단에 사용자 정보 표시
      //setCurUserInfo( cUser.attributes.name +" ( " +cUser.attributes.phone_number +" / " + cUser.attributes.email +" )") ;
      setCurUserInfo( "アカウント："+cUser.attributes.name  ) ;
      //console.log ( cUser) ;

      console.log ( "isPopup", isPopup );
      if ( isPopup==1){
        console.log( "현재작업시간 일치, 팝업 노출 Ok, 액션 금지! 로그인, start 금지.." );
        props.history.push("/noAction");
      } else {
        if ( cUser.attributes.name.length > 0 ){
          console.log ( "login...") ;
          props.history.push("/");  // 테스트로 주석처리. 실제 운영서버 릴리즈시에는 주석 제거 필!!!
        } else {
          console.log ( "not login") ;
          props.history.push("/login");
        }

      } 
    } // ./try
    catch(e) {
      if ( isPopup==1){
        console.log( "catch load...현재작업시간 일치, 팝업 노출 Ok, 액션 금지! 로그인, start 금지.." );
        props.history.push("/noAction");
      } else {
        if (e !== 'No current user') {
          console.log (e);
          // add 2020.06.01
          if ( e.code==="UserNotFoundException"){
            //alert(e.message);
            //alert ( "ユーザー情報が存在しません。 ログインをお願いします。") ;
          }
          props.history.push("/login");
        }else {
          console.log ( "catch load...") ;
          props.history.push("/login");
        }
      }        
    } // ./catch
    setIsAuthenticating(false);
  }
  //-------------------------------------------------------------
  // #4285, Add 안내 팝업 관련.
  //-------------------------------------------------------------
  function isServerWorking(){
    console.log ( "isServerWorking ....") ;
    const today = new Date(); // 현재 날짜를 가져옵니다.
    let year = today.getFullYear(); //년도 구하기
    let month = (today.getMonth()+1);
    let day = today.getDate();
    let hour = today.getHours();
    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    const todayTime = year + '' + month + '' + day + '' + hour ;
    console.log (" -> todayTime -> " +todayTime,
      "shutdownDateTime ->" + config.owl_callback.shutdownDateTime ) ;
    // 작업예정일시를 config.js 에서 가져와서 셋팅하자.
    console.log("작업예정시각정보:"+config.owl_callback.jobDateTime);
    setJobDateTime(config.owl_callback.jobDateTime ); 

    if (parseInt(todayTime) == parseInt(config.owl_callback.shutdownDateTime) )  {
      //console.log( "현재작업시간 일치, 팝업 노출 Ok, 액션 금지! 로그인, start 금지.." );
      //setIsOpen(true) ;
      //return 1 ;  // 팝업 오픈과 로그인 불가
      // '오늘하루보지않음 '체크 여부
      const expireDay = localStorage.getItem("popupNoShow");      
      if(today.getTime() > expireDay) { // 이렇게 하면 localStorage에 아무것도 저장되어 있지 않을 경우 undefined 거나 null 이므로 true를 반환한다.
        setIsOpen(true) ;
        return 1 ; // 팝업 오픈과 로그인 불가 
      } else {
        //return true; // true 이므로 팝업 close
        return 1 ; // 로그인 불가 
      }
      // ./'오늘하루보지않음 '체크 여부
    } else if (parseInt(todayTime) < parseInt(config.owl_callback.shutdownDateTime) )  {
      //setIsOpen(true) ;
      //return 0 ; // 팝업 오픈과 로그인 가능 
      // '오늘하루보지않음 '체크 여부
      const expireDay = localStorage.getItem("popupNoShow");      
      if(today.getTime() > expireDay) { // 이렇게 하면 localStorage에 아무것도 저장되어 있지 않을 경우 undefined 거나 null 이므로 true를 반환한다.
        setIsOpen(true) ;
        return 0 ; // 팝업 오픈과 로그인 가능 
      } else {
        //return true; // true 이므로 팝업 close
        return -1 ; // 팝업 X  
      }
      // ./'오늘하루보지않음 '체크 여부      
    } else if (parseInt(todayTime) > parseInt(config.owl_callback.shutdownDateTime) )  {
      return -1 ; // 팝업 X  
    }
  }
  /*------------------------------------------------------
  -- ChangePassword 버튼 클릭
  ------------------------------------------------------*/
  async function handleChangePassword() {

    props.history.push("changePassword");
  }
  /*------------------------------------------------------
  -- Logout 버튼 클릭
  ------------------------------------------------------*/
  async function handleLogout() {
    await Auth.signOut();   // AWS
    userHasAuthenticated(false);
    setCurUserInfo( "" ) ;
    props.history.push("/login");
    // owl 팝업 close 필요.
    /*
    사용자 생성, 사용자 속성 변경 제외.
    <LinkContainer to="/adminCreateUser">
      <NavItem>CreateUser</NavItem>
    </LinkContainer>
    <LinkContainer to="/adminUpdateUserAttributes">
      <NavItem>UserAttributes</NavItem>
    </LinkContainer>
    */
    /* html
    <Link to="/">チェックアイDXレセプトチェッカー</Link>
    <img src="/rc-ls_log.png" width="250" height="50" border="0" alt=""/>
    */
  }

  return (
  !isAuthenticating &&
  <div className="App container">
    <Navbar fluid collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>
          {isAuthenticated
            ? <>
              <NavItem>{curUserInfo}</NavItem>
              <NavItem onClick={handleLogout}>ログアウト（Logout）</NavItem>
              <NavItem onClick={handleChangePassword}>パスワード変更</NavItem>
              </>
            : <>
                <LinkContainer to="/login">
                  <NavItem>ログイン（Login）</NavItem>
                </LinkContainer>
              </>
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />

    <div>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="modalBody" >
          <h1>サービス一時停止のご案内</h1>
          <img src="img-note-guide02.png"></img>
          <h4>より安定したサービスを提供するために、サーバー作業を以下のように進めます。</h4>
          <h4>ご利用に多少ご不便をおかけしますが、ご了承ください。 </h4>
          <h3>{jobDateTime} </h3>
          <h3>システムアップデートのためご利用できません」</h3>
          
          <br/>
          <span isChecked={isChecked}>
              <input type='checkbox' id='check'onChange={handleChange}/>
              <label htmlFor='check'className="label">今日一日表示しない </label>
          </span>
          <span>
            <button id="modalCloseBtn" onClick={closeModal}>閉じる</button>
          </span>
          
        </div>
      </Modal>
    </div>   
  </div>
  );
}
export default withRouter(App);
